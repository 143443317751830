.progress-modal {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: fixed;
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;


}

.progress-modal .content {
    background-color: white;
    width: 500px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 350px;
    margin: auto;
}
