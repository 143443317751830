.refund-modal {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: fixed;
    background-color: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.refund-modal .content {
    background-color: white;
    width: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: min-content;
    min-height: 300px;
    margin: auto;
}
