.css-ihhycx-MuiTimePickerToolbar-amPmSelection .MuiTimePickerToolbar-ampmLabel {
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.3);
}

.css-12gxo0t-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
    color: black;
}

.holidayName div {
    height: 100%;


}

.react-calendar {
    min-width: 100%;
}

.selected {
    color: #d10000;
}


.special-date {
    position: relative;
}

.except-date {
    position: relative;
}

.except-date::after {
    content: "X"; /* 오버레이 텍스트 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 1;
    pointer-events: none; /* 클릭 방지 */
}

.special-date::after {
    content: "X"; /* 오버레이 텍스트 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 1;
    pointer-events: none; /* 클릭 방지 */
}
