.ballon {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    align-content: center;
    background: #484848;
    color: black;
    border-radius: 5px;
    padding: 12px 12.8px;
    line-break: anywhere;
    font-size: 14px;
}

.MuiButtonBase-root.Mui-selected {
    background-color: #1A73E8 !important;
}
.MuiButtonBase-root.MuiMenuItem-root{
        display: block !important;
}
/*.MuiButtonBase-root{*/
/*    display: block !important;*/
/*}*/

.payment-select-wrapper {
    font-size: 0.875rem;
    text-align: center;
}

.ballon-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    max-width: 400px;
}

.ballon-detail-info {
    color: #999;
    font-size: 11px;
    padding-top: 10px;
}

.ballon-delete-button {
    color: #a00;
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
}

.order-note-status {
    font-size: 12px;
    width: 150px;

}

.delivery-dropdown {
    width: 100%;
    font-size: 15px;
}

.order-note-status-wrapper {
    display: flex;
    font-size: 12px;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
}

.customer-dropdown {
    background-color: #ffffff;
    z-index: 10;
}

.search-dropdown {
    background-color: #ffffff;
    z-index: 11;
    height: 100%;
}

.status-dropdown {
    background-color: #ffffff;
    z-index: 9;
}


.ballon:after {
    border-top-width: 10px;
    border-top-style: solid;
    border-top-color: inherit;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    content: "";
    position: absolute;
    bottom: -10px;
    left: 10%;
}
