.global-spinner-wrapper {
    display: flex;
    position:fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #23221c50;
    justify-content: center;
    align-content: center;
}
