.rdrDateDisplayWrapper {
    z-index: 4;
    position: relative;
    width: 333px;
}

input {
    text-align: center;
}

.rdrMonthAndYearWrapper {
    position: absolute;
    z-index: 3;
    width: 333px;
    display: none;
    padding-top: 75px !important;
}

.rdrMonthsVertical {
    display: none;
    position: absolute;
    z-index: 2;
    padding-top: 80px;
    background-color: #ffffff
}
