dialog {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 8000;
    position: fixed;
    overflow: visible;
}
